import React, {useEffect, useState} from "react";
import {Card, CardBox} from "@amboss/design-system";
import CardWrapper from "../CardWrapper";
import {useAppSelector} from "../../../hooks/redux";
import {selectUser} from "../../../store/profile/profileSlice";
import ProfileCompleted from "./ProfileCompleted";
import ProfileEdit from "./ProfileEdit";
import useProfile from "../../../hooks/useProfile";
import useEditProfile from "../../../hooks/useEditProfile";


export const AccountSetup = () => {
    const profile = useAppSelector(selectUser)
    const profileLoaded = useAppSelector(state => state.profile.profileLoaded)
    const [loading, setLoading] = useState(true)
    const {refreshProfile} = useProfile()
    const {editProfile, closeProfile, openProfile} = useEditProfile()

    useEffect(() => {
        if (profileLoaded) {
            setLoading(false)
        }
    }, [profileLoaded])

    const handleProfileEditSubmit = () => {
        closeProfile()
        refreshProfile()
    }

    return (
        <CardWrapper>
            <Card>
                <CardBox>
                    {!loading && !editProfile ?
                        <ProfileCompleted callback={openProfile}/> :
                        <ProfileEdit callback={handleProfileEditSubmit}/>
                    }
                </CardBox>
            </Card>
        </CardWrapper>
    )
};
