import {useCallback, useContext, useEffect, useState} from 'react';
import Navbar from "../components/navbar/Navbar";
import {Stack, Modal, Link} from "@amboss/design-system";
import {AccountSetup} from "../components/cards/accountSetup/AccountSetup";
import {SocketContext} from "../contexts/SocketProvider";
import useProfile from "../hooks/useProfile";
import {config} from "../config";
import {identify, trackEvent} from "../analytics";
import SocketErrorCard from "../components/cards/SocketErrorCard";
import {isWebview} from '../utils';
import {HomeSections} from '../components/cards/homeSections/HomeSections';
import {useSocketEmit} from '../hooks/useSocketEmit';
import {setTotalCredits, totalCreditsSelector} from "../store/totalCredits/totalCreditsSlice";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import {useTranslation} from "react-i18next";
import useEditProfile from "../hooks/useEditProfile";

const Home = () => {
    const {profile} = useProfile()
    const {socketReady} = useContext(SocketContext)
    const [xid, setXid] = useState<string | null>(null)
    const {socketError} = useContext(SocketContext)
    const {getTotals} = useSocketEmit()
    const dispatch = useAppDispatch()
    const totalCredits = useAppSelector(totalCreditsSelector);
    const [isOpen, setIsOpen] = useState(true);
    const {t} = useTranslation()
    const {openProfile, closeProfile} = useEditProfile()

    const getTotalCredits = useCallback(() => {
        getTotals()
            .then((response) => {
                dispatch(setTotalCredits(response))
            })
            .catch((e) => {
                console.log(e)
            })
    }, []);

    useEffect(() => {
        if (socketReady) {
            getTotalCredits()
        }
    }, [socketReady, getTotalCredits])

    useEffect(() => {
        if (!xid && profile && profile.xid) {
            setXid(profile.xid)
            identify({xid: profile.xid, traits: {}})
            trackEvent({event: config.analytics.events.CME_PAGE_OPENED, options: {xid: profile.xid}})
        }

    }, [profile, xid])

    useEffect(() => {
        if (!isOpen && !profile?.clinicalRole) {
            openProfile();
        }
        if (isOpen) {
            closeProfile();
        }

    }, [isOpen, profile?.clinicalRole])

    return (
        <div style={{padding: "32px 0px"}}>
            {!isWebview() && <Navbar/>}
            {socketReady &&
                <div className='home-container' style={{paddingTop: !isWebview() ? 56 : 0}}>
                    <Stack space={'m'}>
                        <AccountSetup/>
                        {totalCredits && <HomeSections totals={totalCredits}/>}
                    </Stack>
                </div>
            }
            {isOpen && !socketError && <div style={{zIndex: 9999}}>
                <Modal actionButton={{text: t('home.modal.button')}}
                       isDismissible={true}
                       header={''}
                       onAction={() => {
                           setIsOpen(false);
                       }} role='dialog'>
                    <Modal.Text> {t('home.modal.text')} <Link color="accent"
                                                              size="m"
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              href="https://support.amboss.com/hc/en-us/articles/4405038645140-Contacting-AMBOSS-Support">here</Link>.
                    </Modal.Text>
                </Modal>
            </div>
            }
            {socketError && <SocketErrorCard/>}
        </div>
    );
};

export default Home;
