export const en = {
    common: {
        navbar: {
            exit: 'home page',
            help: 'help'
        },
        loading: {
            header: 'Loading credits...',
            subheader: 'please wait for the list to load'
        },
        home: {
            modal: {
                button: 'Ok',
                text: 'At the moment, we’re encountering technical difficulties that may prevent Internet Point of Care CME articles from appearing in users’ accounts for review and credit claiming. Our team is actively working to resolve this issue. If you need assistance, please don’t hesitate to reach out to our Customer Support team'
            },
            cards: {
                ipc: {
                    title: "Internet Point of Care",
                    description: "With AMBOSS you can earn 0.5 CME credits for each clinical question you research. For board-certified physicians, AMBOSS will submit claimed credit for Maintenance of Certification (MOC) to the board(s) selected in your profile.",
                    description_ama: "With AMBOSS you can earn 0.5 CME credits for each clinical question you research. For board-certified physicians, AMBOSS will submit claimed credit for Maintenance of Certification (MOC) to the board(s) selected in your profile.",
                    description_ahpra: "With AMBOSS you can earn 0.5 CPD hours for each clinical question you research."
                },
                abim: {
                    title: "Internal Medicine Board Review",
                    description: "With AMBOSS you can earn 1 CME credit for every 6 board review questions you answer correctly. For board-certified physicians, AMBOSS will submit claimed credit for Maintenance of Certification (MOC) to the board(s) selected in your profile."
                },
                abfm: {
                    title: "Family Medicine Board Review",
                    description: "With AMBOSS you can earn 1 CME credit for every 6 board review questions you answer correctly.  You may report earned credit as AAFP Elective Credit."
                },
                abp: {
                    title: "Pediatrics Board Review",
                    description: "With AMBOSS you can earn 1 CME credit for every 6 board review questions you answer correctly. For board-certified physicians, AMBOSS will submit claimed credit for Maintenance of Certification (MOC) to the board(s) selected in your profile."
                },
            },
            labels: {
                toClaim_one: "{{type}} TO CLAIM",
                toClaim_other: "{{type}}S TO CLAIM",
                toReview_one: "{{type}} TO REVIEW",
                toReview_other: "{{type}}S TO REVIEW",
                claimCredits: "Claim Credits",
                claimHours: "Claim Hours",
                learnMore: "LEARN MORE",
                type: {
                    ipc: {
                        ahpra: {
                            claimed_one: " total hour/credit claimed",
                            claimed_other: " total hours/credits claimed"
                        },
                        ama: {
                            claimed_one: " total credit claimed",
                            claimed_other: " total credits claimed"
                        }
                    },
                    other: {
                        claimed_one: " total credit claimed",
                        claimed_other: " total credits claimed"
                    }

                },
            }
        },
        accountSetup: {
            profileCompleted: {
                edit: 'Edit CME profile',
                calloutText: 'Enter your board information in your CME profile before claiming credits.',
                calloutDescription: 'Board review credits may appear in multiple boards.  For accurate submission, only claim credits that match the board in your profile.',
                calloutTextTop: 'Credits are available to review and claim approximately 24 hours after they are earned.',
            },
            sections: {
                header: {
                    profileEdit: 'First, we need to set up your account.',
                    profileCompleted: 'Welcome back, <1>{{name}}</1>.',
                },
                footer: {
                    button: 'Save',
                    cancel: 'Cancel',
                    link: 'I’m interested in redeeming other types of CME credit.'
                },
                personalInformation: {
                    title: 'Personal information',
                    privacyPolicy: 'Privacy Policy',
                    form: {
                        submitError: 'There was an error while submitting your form. Please check that all data in your profile is correct.',
                        placeholders: {
                            text: 'Enter here',
                            dd: 'DD',
                            mm: 'MM',
                            yyyy: 'YYYY'
                        },
                        inputs: {
                            firstName: 'First name',
                            lastName: 'Last name',
                            birthDate: 'Date of birth',
                            day: 'Day (DD)',
                            month: 'Month (MM)',
                            year: 'Year (YYYY)',
                            email: 'Email',
                            graduationDate: 'Medical school graduation',
                            clinicalRole: {
                                label: 'Select your clinical role',
                                value: 'Specify your role',
                                physician: 'Physician',
                                physician_associate: 'Physician associate',
                                nurse_practitioner: 'Nurse practitioner',
                                nurse: 'Nurse',
                                pharmacist: 'Pharmacist',
                                other: 'Other',
                            },
                            acceptTerms: 'I confirm that I am a physician and thus eligible to obtain CME credits.'
                        },
                        checkbox: {
                            label: 'I confirm that I am a physician and thus eligible to obtain CME credits.'
                        },
                        errors: {
                            general: 'This information is required'
                        }
                    }
                },
                specialtyState: {
                    title: 'SPECIALTY / STATE BOARDS',
                    subtitle: {
                        speciality: "Specialty boards",
                        state: "State boards",
                    },
                    hint: '(OPTIONAL)',
                    instructions: 'Please ensure that the personal details you have entered above match those on file with your board.',
                    select: {
                        labels: {
                            specialty: 'Specialty Board',
                            state: 'State Board',
                        },
                        placeholder: 'Select board'
                    },
                    input: {
                        labels: {
                            specialty: 'Specialty board ID',
                            state: 'State license number',
                        }
                    },
                },
                cmeCpdCe: {
                    title: 'CME/CPD/CE Credit Type',
                    label: 'Association',
                    optionLabels: {
                        ama: 'AMA',
                        ahpra: 'AHPRA (Australia)'
                    },
                    hintLabels: {
                        ama: '<text>AMA PRA Category 1 Credit(s)™</text>',
                        ahpra: '<text>AHPRA CPD Activity Hours</text>'
                    },
                }
            }
        },
        credits: {
            title: 'Credits',
            subTitle: 'You can earn CME credits for each clinical question you research on AMBOSS. AMBOSS will submit claimed credits for maintenance of certification (MOC) and/or licensure to the board(s) you have selected in your profile.',
            modal: {
                add: 'Add',
                discard: 'Discard',
                submit: 'Submit'
            },
            tabs: {
                tab1: {
                    title: 'For review',
                    listItem: {
                        searchTerm: 'Search: <1>"{{searchTerm}}"</1>',
                        button_one: 'Review for {{count}} {{type}}',
                        button_other: 'Review for {{count}} {{type}}s',
                        itemValue_one: '{{count}} correctly answered question',
                        itemValue_other: '{{count}} correctly answered questions'
                    },
                    emptyPlaceholder: {
                        header: 'No credits available for review',
                        subHeader: 'You have not yet earned any CME credits. Go to AMBOSS to search for answers to your clinical questions.',
                    },
                    review: {
                        error: 'There was an error while executing your request. Please check your form or try again later.',
                        modal: {
                            title: 'Please answer the following questions.',
                        },
                        questions: {
                            q1: 'When you searched this topic, which of the following were you hoping to find information about? (Please select all that apply.)',
                            q1a1: 'Clinical features',
                            q1a2: 'Diagnosis',
                            q1a3: 'Treatment/management',
                            q1a4: 'Prevention',
                            q1a5: 'Other (e.g. prognosis)',
                            q2: 'How did the information learned affect your clinical practice? (Please select all that apply.)',
                            q2a1: 'It confirmed my current plan or practice.',
                            q2a2: 'It changed what I did in practice.',
                            q2a3: 'It changed what I plan to do in the future.',
                            q2a4: 'I did not find information that answered my question.',
                        }
                    }
                },
                tab2: {
                    title: 'To claim',
                    selectAll: 'Select all',
                    calloutText: 'You earn 1 CME credit for every {{answersNeeded}} correctly answered questions. You have $t(credits.tabs.tab2.answer, {"count": {{answer}} }) for a total of $t(credits.tabs.tab2.credit, {"count": {{credit}} }). Answer $t(credits.tabs.tab2.question, {"count": {{question}}}) correctly to claim an additional credit.',
                    answer_one: '{{count}} correct answer',
                    answer_other: '{{count}} correct answers',
                    credit_one: '{{count}} credit',
                    credit_other: '{{count}} credits',
                    question_one: '{{count}} more  question',
                    question_other: '{{count}} more questions',
                    listItem: {
                        badge_other: '{{count}} {{type}}s',
                        badge_one: '{{count}} {{type}}',
                    },
                    emptyPlaceholder: {
                        header: 'No credits available to be claimed',
                        subHeader: '',
                    },
                    claim: {
                        error: 'There was an error while executing your request. Please check your form or try again later.',
                        modal: {
                            title: 'Please rate your level of agreement with the following statements:',
                            radio: {
                                label: 'Choose one'
                            },
                            textarea: {
                                hint: 'Max. 256 characters'
                            },
                            questions: {
                                q1: 'The information I learned has improved my ability to provide evidence-based patient care.',
                                q2: 'I was able to quickly find the information I needed.',
                                q3: 'The information presented was based on updated evidence sources.',
                                q4: 'The information presented was free of commercial bias.',
                            },
                            answers: {
                                a1: 'Agree',
                                a2: 'Somewhat agree',
                                a3: 'Somewhat disagree',
                                a4: 'Disagree',
                            },
                            q4reason: 'If you did not select “Agree” in the question above, please provide details.',
                            note: 'Please describe a change you have made to your clinical practice as a result of information you learned through AMBOSS.',
                        },
                    },
                    bottomModal: {
                        titleSubmit: 'Submit to board',
                        titleClaim: 'Claim Credits',
                        tip: 'If you would like these credits to be submitted to a board for maintenance of certification (MOC) or licensure, please ensure that the board information in your settings is fully updated BEFORE clicking Submit. When you click Submit, these credits will be automatically submitted to the board(s) listed in your settings, and the submission cannot be modified.',
                        link: 'Manage my boards',
                        buttonSubmit: {
                            enabled_other: 'Claim {{count}} credits',
                            enabled_one: 'Claim {{count}} credit',
                            disabled: 'Select to submit'
                        },
                        buttonClaim: {
                            enabled_other: 'Claim {{count}} credits',
                            enabled_one: 'Claim {{count}} credit',
                            disabled: 'Select to claim'
                        },

                    },

                },
                tab3: {
                    title: 'Claimed',
                    listItem: {
                        ama: '<text>AMA PRA Category 1 Credit(s)™</text>',
                        ahpra: '<text>AHPRA CPD Activity Hours</text>',
                        ipc: 'Internet Point of Care CME',
                        button: 'PDF',
                        resubmit: 'RESUBMIT',
                    },
                    emptyPlaceholder: {
                        header: 'No credits claimed',
                        subHeader: 'Go to “For review” to review your searches.',
                    },
                    tooltip: {
                        general: "Your credit(s) could not be submitted.",
                        rejected: "Please check that all the information in your CME profile is correct.",
                        rejected_generic: "Please check that all the information in your CME profile is correct.",
                        rejected_specific: "{{message}} (Error {{code}}). If you are unable to correct this error and resubmit, please contact customer support.",
                        notSubmitted: "To submit credits to boards that participate in electronic CME credit submission, add a board to your CME profile."
                    },
                    download: {
                        error: 'Error while downloading the document. Please try reloading the CME page from the AMBOSS platform.'
                    }
                }

            }
        }
    }
}
